<template>
    <div>
        <div style="font-size: 16px;font-weight:600;" class="page-header">
            <i @click="back()" style="cursor:pointer;margin-right:4px;" class="iconfont icon-Chevron"></i>
            <span @click="back()" style="cursor:pointer;margin-right:4px;color: #409EFF;">返回</span>
            <i style="margin-right:4px;" class="iconfont icon-Chevron"></i>
            <span>邮件营销</span>
        </div>
        <el-form :model="ruleForm" :rules="rules" ref="formCheck" label-width="130px" class="login-form">
            <div class="block">
                <div class="header">邮件推送</div>
                <div class="list">
                    <el-form-item prop="title" label="营销活动标题：">
                        <el-input v-model="ruleForm.title" placeholder="请输入营销活动标题" style="max-width:300px;"></el-input>
                    </el-form-item>
                    <el-form-item prop="tag4" label="营销目的：">
                        <el-checkbox-group v-model="ruleForm.tag4">
                            <el-checkbox v-for="(item,idx) in tagList4" :key="idx" :label="item.id">
                                {{item.name}}
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="模板选择">
                        <div class="flex">
                            <div style="margin-right:32px;">
                                <el-select v-model="ruleForm.tpl_id"  placeholder="请选择">
                                    <el-option v-for="item in tempList" :key="item.id" :label="item.name" :value="item.id">
                                    </el-option>
                                    <el-option :label="'自定义'" :value="''">自定义</el-option>
                                </el-select>
                            </div>
                            <div>
                                <!-- <el-button style="margin-right:32px;" @click="selectSets()" type="primary" plain>选择已有套系</el-button> -->
                                <router-link :to="{ path:'/client/mailTemplate' }" target="_blank">
                                    <el-button type="primary" plain>新建模板</el-button>
                                </router-link>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item v-if="ruleForm.tpl_id==''" style="margin-bottom:0px;" prop="title" label="内容：">
                        <div style="height:565px">
                            <quill v-model="ruleForm.content"></quill>
                        </div>
                    </el-form-item>
                    <!-- <div class="flex">
                        <div style="width:130px;text-align:right;padding-right:12px;">内容：</div>
                        <div class="flex-1" style="height:580px">
                            <quill v-model="ruleForm.content"></quill>
                        </div>
                    </div> -->
                </div>
                <div class="flex flex-justify-around" style="width:400px;margin:0 auto;padding:30px 0;">
                    <div><el-button @click="submitForm(1)" style="width: 80px;margin-right:20px;" type="primary">预发送</el-button></div>
                    <div><el-button @click="submitForm(2)" style="width: 80px;" type="primary">确定</el-button></div>
                </div>
            </div>
        </el-form>

        <el-dialog :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
            <SendMail :form="form" @hide="popVisible=false"></SendMail>
        </el-dialog>
    </div>
</template>
<script>
    import { clone } from 'lodash';
    import { IMG_URL_PRE,FILE_API } from '@/config';
    import { listTag,listMailTpl,sendMail } from '@/service/crm';
    import SendMail from './SendMail.vue';
    import quill from '@/components/quill.vue';
    export default {
        components: {
            SendMail,quill
        },
        data() {
            return {
                popTitle:null,
                popVisible:false,
                rules: {
                    title:[{required: true, message: '请输入营销活动标题', trigger: 'blur'}],
                    tag4:[{required: true, message: '请选择标签', trigger: 'blur'}],
                    tpl_id:[{required: true, message: '请选择模板', trigger: 'blur'}],
                    value:[{ required: true, message: '请填写内容', trigger: 'blur' }],
                },
                ruleForm:{
                    comp_ids:[],
                    title:null,
                    tag4:[],
                    tpl_id:null,
                    content:null,
                },
                tagList4:[],
                tempList:[],
                form:null,
            }
        },
        async created() {
            this.ruleForm.comp_ids = this.$route.query.ids.split('.');
            this.tagList4 = await listTag({tag_type:4,stat:1,});
            this.tempList = await listMailTpl();
        },
        mounted() {},
        methods: {
            goto(link) {
                this.$router.push(link);
            },
            back() {
                this.$router.go(-1)
            },
            selectTemp() {
                let tpl_param = this.tempList.find((e)=> {
                    return e.id == this.ruleForm.tpl_id;
                })
                this.ruleForm.tpl_param = tpl_param.params;
                console.log(tpl_param);
            },
            submitForm(type) {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        if (type==1) this.send();
                        if (type==2) this.submit();
                    }
                });
            },
            send() {
                this.popTitle = "预发送";
                this.popVisible = true;
                this.form = {
                    mail:null,
                    title:this.ruleForm.title,
                }
                if (this.ruleForm.tpl_id) this.form.tpl_id=this.ruleForm.tpl_id;
                if (this.ruleForm.content) this.form.tpl_id=this.ruleForm.tpl_id;
            },
            submit() {
                let _item = clone(this.ruleForm);
                if (!_item.tpl_id) this.$delete(_item,"tpl_id");
                if (!_item.content) this.$delete(_item,"content");
                console.log(_item);
                sendMail(this.ruleForm).then(rst => {
                    this.back();
                    this.$message.success("发送成功");
                }).catch(err => {
                    this.$message.error(err.message);
                });
            },
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>
    .list {
        padding: 20px;
    }
    .template-title>div:first-child {
        width:110px;
        padding:0 10px 20px 0;text-align:right;
    }
    .template-item {
        max-width: 300px;
    }
</style>