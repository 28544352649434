<template>
    <div style="max-width: 320px;margin: 20px auto;">
        <el-form :model="ruleForm" :rules="rules" ref="formCheck" label-width="60px" class="login-form">
            <el-form-item prop="mail" label="邮箱号">
                <el-input placeholder="请输入邮箱账号" v-model="ruleForm.mail"></el-input>
            </el-form-item>
            <div class="flex flex-justify-around" style="padding-top:50px;">
                <div><el-button @click="hide()" style="width: 80px;" type="primary" plain>取消</el-button></div>
                <div><el-button @click="submitForm()" style="width: 80px;" type="primary">确定</el-button></div>
            </div>
        </el-form>
    </div>
</template>
<script>
    import { preSendMail } from '@/service/crm';
    export default {
        props: ['form'],
        data() {
            let checkMail = (rule, value, callback) => {
                if (!value) {
                return callback(new Error('请输入邮箱账号'));
                } else {
                    const reg = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
                    if (reg.test(value)) {
                        callback();
                    } else {
                        return callback(new Error('请输入正确的邮箱账号'));
                    }
                    // callback();
                }
            };
            return {
                rules: {
                    mail: [{validator: checkMail, trigger: 'blur'}],
                },
                ruleForm:{
                    mail:null,
                },
            }
        },
        watch:{
            form:{
                handler() {
                    this.ruleForm = this.form;
                },
                deep: true
            }
        },
        created() {
            this.ruleForm = this.form;
            console.log(this.ruleForm);
        },
        mounted() {},
        methods: {
            hide() {
                this.$emit("hide");
            },
            submitForm() {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        this.submit();
                    }
                });
            },
            submit() {
                preSendMail(this.ruleForm).then(rst => {
                    this.hide();
                    this.$message.success("发送成功");
                }).catch(err => {
                    this.$message.error(err.message);
                });
            },
        }
    }
</script>
<style scoped>

</style>